<template>
	<div class="app-view">
		<van-nav-bar title="修改订单尾号" left-text="返回" left-arrow @click-left="$routerGo(-1)" @click-right="saveNickname" right-text="保存" />
		<van-cell-group>
            <van-field v-model="order_remark" label="订单尾号" rows="5" autosize type="textarea" placeholder="请输入订单尾号，多个用逗号隔开" />
        </van-cell-group>
	</div>
</template>
<script>
export default {
	data() {
		return {
            order_remark: '',
		}
	},
	created(){
		this.order_remark = this.$store.state.user.userInfo.order_remark || ''
	},
    methods:{
        saveNickname() {
            this.$ask.post('/updateUserInfo', {order_remark: this.orderremark})
                .then(res => {
                    this.$store.dispatch('login',res.data)
                    this.$toast('修改成功')
                })
                .catch(this.$toastError)
        }
    }
}
</script>
